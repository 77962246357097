@tailwind base;

@tailwind components;

@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: "transparent";
  /* #212032; */
}

::-webkit-scrollbar-thumb {
  background: #1178f880;
}

::-webkit-scrollbar-thumb:hover {
  background: #1178f8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div .button {
  margin: 0 15px 15px 0;
}
.video-contain video {
  object-fit: contain;
  overflow:hidden
}
textarea:focus{
  outline: none;
  border-color: none;
  box-shadow: none;
}

input:focus-visible{
  outline: none;
  border-color: none;
  box-shadow: none;
}

.video-cover video {
  position: absolute;
  object-fit: cover;
  
}

.video-contain video {
  position: absolute;
  object-fit: contain;
}

input[type="radio"]:checked + label span {
background-color: #fff; 
box-shadow: 0px 0px 0px 2px black inset;
}


input[type="radio"]:checked + label{
 color: #fff; 
}


.flying-emojis {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
  pointer-events: none;
  user-select: none;
  z-index: 99;
}
.emoji {
  position: absolute;
  bottom: 0px;
  left: 50%;
  font-size: 48px;
  line-height: 1;
  width: 48px;
  height: 48px;
}
.emoji.wiggle-1 {
  animation: emerge 3s forwards,
    wiggle-1 1s ease-in-out infinite alternate;
}
.emoji.wiggle-2 {
  animation: emerge 3s forwards,
    wiggle-2 1s ease-in-out infinite alternate;
}
@keyframes emerge {
  to {
    bottom: 85%;
    opacity: 0;
  }
}
@keyframes wiggle-1 {
  from {
    margin-left: -50px;
  }
  to {
    margin-left: 50px;
  }
}
@keyframes wiggle-2 {
  from {
    margin-left: 50px;
  }
  to {
    margin-left: -50px;
  }
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.carousel-open:checked + .carousel-item {
  position: static;
  opacity: 100;
}

.carousel-item {
  position: absolute;
  opacity: 0;
  -webkit-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
}

.carousel-item img {
  display: block;
  height: auto;
  max-width: 100%;
}

.carousel-control {
  background: rgba(0, 0, 0, 0.28);
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: none;
  font-size: 30px;
  height: 35px;
  line-height: 30px;
  position: absolute;
  top: 25%;
  -webkit-transform: translate(0, -50%);
  cursor: pointer;
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  text-align: center;
  width:35px;
  z-index: 10;
}

.carousel-control.prev {
  left: 2%;
}

.carousel-control.next {
  right: 2%;
}

.carousel-control:hover {
  background: rgba(0, 0, 0, 0.8);
  color: #aaaaaa;
}

#carousel-1:checked ~ .control-1,
#carousel-2:checked ~ .control-2,
#carousel-3:checked ~ .control-3 {
  display: block;
}


.start-node, .end-node {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  line-height: 64px;
  color: #fff;
  text-align: center;
}

.start-node {
  background-color: #338aff;
}

.end-node {
  background-color: #666;
}

.other-node, .condition-node {
  width: 224px;
  border-radius: 4px;
  color: #666;
  background: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
}

.other-node {
  height: 118px;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.condition-node {
  height: 44px;
  padding: 12px 16px;
}

.characterForm {
	width: 100%;
}

.characterForm fieldset {
	max-width: none !important;
}

.characterForm fieldset div {
	margin-right: 0px !important;
}


.characterForm label {
	color: #2b2b2b;
	font-weight: 500;
	margin-top: 24px !important;
	font-size: 16px;
}

.characterForm input {
	background-color: transparent;
	border: 1px solid #86886C1A;
	width: 100% !important;
	padding: 8px;
	color: #2b2b2b;
	border-radius: 0px;
	/* margin-bottom: 24px; */
	/* margin-top: 12px; */
  font-family: 'Courier New', Courier, monospace;
  font-weight: 600;
}

.characterForm input:focus-visible {
	outline: none !important;
}


.characterForm input[type="submit"]  {
	padding-right: 16px;
    padding-left: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    background-color: #2b2b2b;
    font-size: 16px;
    font-weight: bold;
    margin-top: 24px;
    color: white;
	border: none;
}

.characterForm .hs-email {
   
    margin-top: 24px;
    width: 100% !important; 
    
}

.characterForm .hs_select_a_language {
  margin-top: 24px;
  width: 100% !important; 
}

.characterForm select {
	padding: 8px;
	border: 1px solid #86886C1A;
  width: 100%;
  color: #2b2b2b;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 600;
}

.characterForm li {
	list-style: none;
	margin: 0px;
	padding: 0px;
	font-size: 12px;
	margin-top: 8px;
}

.characterForm #label-email-9192b1a3-866c-48de-8398-f8a3d8210c52 {
   display: none;
}